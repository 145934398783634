<template>
  <a-modal
    :visible="visible"
    :title="model.readOnly ? 'Lihat Berita' : form.id ? 'Edit Berita' : 'Tambahkan Berita Baru'"
    @ok="handleOk"
    @cancel="handleCancel"
    width="1000px"
    :destroy-on-close="true"
  >
    <template #footer>
      <a-button key="back" :hidden="model.readOnly" @click="handleCancel">Cancel</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :hidden="model.readOnly"
        @click="handleOk"
        >Submit</a-button
      >
    </template>
    <a-form :model="form" :rules="rules" class="myform" ref="formRef">
      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Jenis Berita"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="type_news"
          >
            <filter-promo
              type="text"
              style="width: 100%"
              v-model:value="type_news"
              v-model:group="filter_type_news"
              placeholder="Pilih Jenis Berita"
              :mode="null"
              :disabled="model.readOnly"
              required
            ></filter-promo>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama Berita"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="name"
          >
            <a-input
              width="100%"
              v-model:value="name"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Deskripsi"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <quill-editor
              class="mb-2"
              v-model:content="description"
              content-type="html"
              toolbar="full"
              :read-only="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Kode Voucher"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-input
              style="width:100%"
              v-model:value="code_promo"
              :disabled="model.readOnly"
              required
            >
              <template #addonBefore>
                <a-tooltip>
                  <template #title>
                    <span>Kode Promosi yang akan tampil di Halaman AksesToko, silahkan klik icon di sebelah kanan untuk menambahkan Kode Promosi secara otomatis</span>
                  </template>
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
              <template #addonAfter>
                <a-button type="link" @click="addVoucher()"><i class="fa fa-gear"></i></a-button>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Tanggal Mulai"
            label-align="left"
            :label-col="{ sm: { span: 12 } }"
            :wrapper-col="{ sm: { span: 24 - 12 }}"
            required
            has-feedback
            name="start_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="start_date"
              format="DD MMMM YYYY"
              placeholder="Start Date"
              :disabled="model.readOnly"
              required
              />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Berakhir"
            label-align="top"
            :label-col="{ sm: { span: 10 } }"
            :wrapper-col="{ sm: { span: 24 - 10 } }"
            required
            has-feedback
            name="end_date"
          >
            <a-date-picker
              style="width: 100%"
              v-model:value="end_date"
              :disabled-date="disabledDate"
              format="DD MMMM YYYY"
              placeholder="End Date"
              :disabled="model.readOnly"
              required
              />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Jenis Promo"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="type_promo"
          >
            <a-tooltip>
              <template #title>
                <p>
                  <b>Jenis Promosi</b>
                  <br>
                  <br>
                  <b>&gt;</b> Pilih Type Nominal jika anda memberikan promosi berupa ~nominal~ Rupiah. 
                  <br><b>&gt;</b> Pilih Type Percentase jika anda memberikan promosi berupa % untuk Toko.
                </p>
              </template>
              <a-button><span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span></a-button>
            </a-tooltip>
            <filter-promo
              type="text"
              style="width: 80%"
              v-model:value="type_promo"
              v-model:group="filter_type_promo"
              placeholder="Pilih Jenis Promo"
              :mode="null"
              :disabled="model.readOnly"
              required
            ></filter-promo>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Tipe Minimal Pembelian"
            label-align="left"
            :label-col="{ sm: { span: 12 } }"
            :wrapper-col="{ sm: { span: 24 - 12 }}"
          >
            <filter-promo
              type="text"
              style="width: 100%"
              v-model:value="type_min_pembelian"
              v-model:group="filter_type_min_pembelian"
              placeholder="Pilih Tipe Pembelian"
              :mode="null"
              :disabled="model.readOnly"
            ></filter-promo>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Kuota"
            label-align="top"
            :label-col="{ sm: { span: 10 } }"
            :wrapper-col="{ sm: { span: 24 - 10 } }"
            required
            has-feedback
            name="quota"
          >
            <a-input
              type="number"
              v-model:value="quota"
              placeholder="Kuota"
              :disabled="model.readOnly"
              required
              >
              <template #suffix>
                <a-tooltip title="Silahkan tambahkan total quota Kode Promosi yang dapat digunakan.">
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Maksimal Promosi Per Toko"
            label-align="left"
            :label-col="{ sm: { span: 12 } }"
            :wrapper-col="{ sm: { span: 24 - 12 }}"
            required
            has-feedback
            name="max_toko"
          >
            <a-input
              type="number"
              style="width: 100%"
              min="0"
              v-model:value="max_toko"
              placeholder="Promosi per toko"
              :disabled="model.readOnly"
              required
              >
              <template #suffix>
                <a-tooltip title="Silakan tambahkan maksimal kuota yang di dapat oleh masing-masing toko.">
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Maksimal Diskon"
            label-align="top"
            :label-col="{ sm: { span: 10 } }"
            :wrapper-col="{ sm: { span: 24 - 10 } }"
          >
            <a-input
              type="number"
              style="width: 100%"
              min="0"
              v-model:value="max_discount"
              :disabled="model.readOnly"
              >
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Minimal Transaksi"
            label-align="left"
            :label-col="{ sm: { span: 12 } }"
            :wrapper-col="{ sm: { span: 24 - 12 }}"
            required
            has-feedback
            name="min_trx"
          >
            <a-input
              type="number"
              v-model:value="min_trx"
              min="0"
              placeholder="Minimal Transaksi"
              :disabled="model.readOnly"
              required
              >
              <template #suffix>
                <a-tooltip title="Toko hanya bisa menggunakan promo ini apabila total transaksi lebih dari / sama dengan Minimal Transaksi. Nilai -1 berarti tanpa batasan.">
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Maksimal Transaksi"
            label-align="top"
            :label-col="{ sm: { span: 10 } }"
            :wrapper-col="{ sm: { span: 24 - 10 } }"
            required
            has-feedback
            name="max_trx"
          >
            <a-input
              type="number"
              style="width: 100%"
              min="0"
              v-model:value="max_trx"
              placeholder="Maksimal Transaksi"
              :disabled="model.readOnly"
              required
              >
              <template #suffix>
                <a-tooltip title="Toko hanya bisa menggunakan promo ini apabila total transaksi kurang dari / sama dengan Maksimal Transaksi. Nilai -1 berarti tanpa batasan.">
                  <span role="img" aria-label="question-circle" type="primary" class="anticon anticon-question-circle"><svg focusable="false" class="" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" viewBox="64 64 896 896"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg></span>
                </a-tooltip>
              </template>
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Nominal Potongan"
            label-align="left"
            :label-col="{ sm: { span: 12 } }"
            :wrapper-col="{ sm: { span: 24 - 12 }}"
            required
            has-feedback
            name="value"
          >
            <a-input
              type="number"
              width="100%"
              v-model:value="value"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
        <a-col :md="12" :sm="24">
          <a-form-item
            label="Minimal Kuantitas Pembelian"
            label-align="left"
            :label-col="{ sm: { span: 10 } }"
            :wrapper-col="{ sm: { span: 24 - 10 } }"
          >
            <a-input
              type="number"
              width="100%"
              v-model:value="min_pembelian"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Metode Pembayaran"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="payment_methods"
          >
            <filter-promo
              type="text"
              style="width: 100%"
              v-model:value="payment_methods"
              v-model:group="filter_payment_methods"
              placeholder="Pilih Metode Pembayaran"
              :disabled="model.readOnly"
              required
            ></filter-promo>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="type_news !== 'info'" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Syarat"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-input
              width="100%"
              v-model:value="syarat"
              :disabled="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Produk"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <filter-products
              type="text"
              style="width: 100%"
              v-model:value="products"
              :disabled="model.readOnly"
            ></filter-products>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="User"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <filter-toko
              type="text"
              style="width: 100%"
              v-model:value="users"
              :disabled="model.readOnly"
              :join-options="model.record.users"
            ></filter-toko>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Device"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <filter-promo
              type="text"
              style="width: 100%"
              v-model:value="devices"
              v-model:group="filter_devices"
              placeholder="Pilih Device"
              :disabled="model.readOnly"
            ></filter-promo>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Banner"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            help="Recomended: Width:2000px, Heigth:1000px, Dimension 2:1, File Size:2048Kb, File Type:jpg/png/gif/jpeg"
            :required="type_news === 'info'"
            :has-feedback="type_news === 'info'"
            :name="type_news === 'info' ? 'image' : ''"
          >
          <template v-if="!model.readOnly">
            <a-input
              v-if="!image || image === 'null'"
              :required="type_news === 'info'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'image')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  image = null
                  url_image = null
                }
              "
            >Hapus banner</a-button>
          </template>
          <img
            v-if="image !== 'null'"
            style="display: block;
            max-width:150px;
            width: auto;
            height: auto;"
            :src="typeof image === 'string' ? image : url_image"
          />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Status"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-radio-group :disabled="model.readOnly" v-model:value="status" required>
              <a-radio :value="1">Aktif</a-radio>
              <a-radio :value="0">Tidak Aktif</a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Artikel"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
          >
            <a-switch v-model:checked="article_flag" :disabled="model.readOnly" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="article_flag" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Nama Artikel"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            has-feedback
            name="article_title"
          >
            <a-input
              width="100%"
              v-model:value="article_title"
              :disabled="model.readOnly"
              required
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="article_flag" class="form-row">
        <a-col :sm="24">
          <a-form-item
            label="Konten Artikel"
            label-align="left"
            :label-col="{ sm: { span: 6 } }"
            :wrapper-col="{ sm: { span: 24 - 6 } }"
            required
            name="article_content"
          >
            <quill-editor
              class="mb-2"
              v-model:content="article_content"
              content-type="html"
              toolbar="full"
              :read-only="model.readOnly"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
  </a-modal>
</template>

<script>
import { defineComponent, reactive, toRef, toRefs, ref, computed, onBeforeMount } from 'vue'
import apiClient from '@/services/axios'
import { message } from 'ant-design-vue'
import FilterPromo from '@/components/filter/FilterPromo'
import FilterProducts from '@/components/filter/FilterProduct'
import FilterToko from '@/components/filter/FilterToko'
import { serialize } from 'object-to-formdata'
import moment from 'moment'

export default defineComponent({
  components: {
    FilterPromo,
    FilterProducts,
    FilterToko,
  },
  props: {
    visible: [Boolean],
    model: {
      type: [Object],
      default: () => ({
        id: null,
        name: null,
        description: null,
        code_promo: null,
        start_date: null,
        end_date: null,
        type_news: null,
        type_promo: null,
        type_min_pembelian: null,
        image: null,
        total_items: null,
        quota: null,
        max_toko: null,
        value: null,
        min_pembelian: null,
        max_discount: null,
        min_trx: null,
        max_trx: null,
        syarat: null,
        article_flag: false,
        article_title: null,
        article_content: null,
        status: 0,
        devices: [],
        payment_methods: [],
        products: [],
        users: [],
        record: {},
      }),
    },
  },
  emits: ['handleOk', 'update:visible'],
  setup(props, { emit }) {
    const form = reactive(Object.assign({}, props.model.record))

    const rules = {
      type_news: {
        required: true,
        message: 'Jenis berita tidak boleh kosong!',
      },
      name: {
        required: true,
        message: 'Nama berita tidak boleh kosong!',
      },
      start_date: {
        required: true,
        message: 'Tanggal mulai tidak boleh kosong!',
      },
      end_date: {
        required: true,
        message: 'Tanggal berakhir tidak boleh kosong!',
      },
      type_promo: {
        required: true,
        message: 'Jenis promo tidak boleh kosong!',
      },
      quota: {
        required: true,
        message: 'Total kuota tidak boleh kosong!',
      },
      max_toko: {
        required: true,
        message: 'Promosi per toko tidak boleh kosong!',
      },
      min_trx: {
        required: true,
        message: 'Minimal transaksi tidak boleh kosong!',
      },
      max_trx: {
        required: true,
        message: 'Maksimal transaksi tidak boleh kosong!',
      },
      value: {
        required: true,
        message: 'Nominal potongan tidak boleh kosong!',
      },
      payment_methods: {
        required: true,
        message: 'Metode pembayaran tidak boleh kosong!',
      },
      image: {
        required: true,
        message: 'Banner tidak boleh kosong!',
      },
      article_title: {
        required: true,
        message: 'Nama artikel tidak boleh kosong!',
      },
      article_content: {
        required: true,
        message: 'Konten artikel tidak boleh kosong!',
      },
    }

    const formRef = ref()
    const errorMessage = ref(null)
    const addNumber = ref()
    const filter_type_news = ref('type_news')
    const filter_type_promo = ref('type_promo')
    const filter_type_min_pembelian = ref('type_min_pembelian')
    const filter_payment_methods = ref('payment_methods')
    const filter_devices = ref('device')
    const image = toRef(form, 'image')
    const dbs = computed(() => form.start_date)

    const disabledDate = (current) => {
      return current && current < dbs.value;
    };

    const fotos = reactive({
      url_image: undefined,
    })

    const ifImage = ref(true)

    const formatMoment = (value) => {
      return value instanceof moment ? value.format('YYYY-MM-DD') : value
    }

    const handleOk = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        return
      }

      form.devices = form?.devices?.map(function (item) {
        return {value: item}
      }) ?? []

      form.payment_methods = form?.payment_methods?.map(function (item) {
        return {paymentValue: item}
      }) ?? []

      form.code_promo = String(form.code_promo)
      form.article_title = form.article_flag ? form.article_title : null
      form.article_content = form.article_flag ? form.article_content : null

      const form_data = serialize(
        {
          ..._.omit(form, [
            'start_date',
            'end_date',
            'is_deleted',
            'created_at',
            'updated_at',
            'created_by',
            'updated_by',
          ]),
          start_date: formatMoment(form.start_date),
          end_date: formatMoment(form.end_date),
          image: image.value,
        },
        { indices: true },
      )

      const url = `/api/pos-promo${form.id ? '/' + form.id + '?_method=PUT' : ''}`
      apiClient
        .post(url, form_data)
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          message.success('Berhasil menyimpan')
          emit('handleOk', form, true)
        })
        .catch(async error => {
          message.error('Gagal menyimpan')
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const handleCancel = e => {
      emit('update:visible', false)
    }

    onBeforeMount(()=>{
      form.devices = form?.devices?.map(function (item) {return item?.value}) ?? []
      form.payment_methods = form?.payment_methods?.map(function (item) {return item?.paymentValue}) ?? []
      form.products = form?.products?.map(function (item) {return item?.id}) ?? []
      form.users = form?.users?.map(function (item) {return item?.id}) ?? []
    })

    const addVoucher = () => {
      addNumber.value = parseInt((Math.random() * 9 + 1) * Math.pow(10,9), 10)
      form.code_promo = addNumber
    }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files

      const allowed_extensions = new Array('jpg', 'png', 'gif', 'jpeg')
      const isExtOk = fileName => {
        const file_extension = fileName
          .split('.')
          .pop()
          .toLowerCase()
        return allowed_extensions.includes(file_extension)
      }

      if (!files.length) return
      // reset visual of file uploader because of html bug
      const resetVisualUploader = async () => {
        if ('image' === type) {
          image.value = true
        }
        await nextTick()

        if ('image' === type) {
          image.value = null
          form.image = null
          fotos.url_image = null
        }
      }

      // only 1 file upload allowed, lower than 2mb and some ext of image files
      // but not for document
      if (!isExtOk(files[0].name) && type !== 'document') {
        message.warning(`Hanya Boleh Gambar ${allowed_extensions}`)
        await resetVisualUploader()
        return
      }

      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        await resetVisualUploader()
        return
      }

      let fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      const gambar = new Image();
      fileReader.onload = function (e) {
          gambar.src = e.target.result;
          gambar.onload = function() {
              var height = this.height;
              var width = this.width;
              if (height < 1000 || width < 2000) {
                message.warning('Ukuran minimal width:2000px, heigth:1000px, dimension 2:1!', 5.0)
                image.value = null
                form.image = null
                fotos.url_image = null
                return
              }
          }
      }

      if ('image' === type) {
        image.value = files[0]
        imageToBase64(image.value, toRefs(fotos).url_image)
      }
    }

    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    return {
      addVoucher,
      addNumber,
      form,
      ...toRefs(form),
      emit,
      rules,
      handleOk,
      handleCancel,
      formRef,
      errorMessage,
      filter_type_news,
      filter_type_promo,
      filter_type_min_pembelian,
      filter_payment_methods,
      filter_devices,
      ifImage,
      image,
      disabledDate,
      onFileChange,
      ...toRefs(fotos),
    }
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
